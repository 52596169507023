import styled from "styled-components";
export const LayoutHeader = styled.div`
  min-height: 100.5px !important;
  max-height: 100.5px !important;
  height: 100.5px !important;

  @media (max-width: 736px) {
    min-height: 63.5px !important;
    max-height: 63.5px !important;
    height: 63.5px !important;
    &.active {
      min-height: 63.5px !important;
    }
  }
`;
export const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  min-height: 100px;
  position: relative;

  &.paddingInside {
    min-height: unset;
    background: #1d1e22;
    border-radius: 14px;
    color: #fff;
    padding: 16px 28px 16px 28px;
  }
  @media (max-width: 1024px) {
    padding: 1rem 1.2rem;
  }

  @media (max-width: 820px) {
    min-height: 75px;
    padding: 1rem;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;
export const HeaderItems = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  min-height: 60px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  cursor: pointer;
  color: var(--text-color);
  padding-right: 15px;
  ${"" /* position: relative; */}

  span {
    letter-spacing: 2px;

    &:after {
      content: ">";
    }
  }

  span {
    letter-spacing: 2px;
    position: relative;
    display: inline-flex;
    align-items: center;

    &::after {
      ${
        "" /* content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      border-right: 2px solid currentColor;
      border-bottom: 2px solid currentColor;
      transform: rotate(45deg);
      margin-left: 8px;
      transition: transform 0.3s ease-in-out, color 0.3s ease-in-out; */
      }
      content: "";
      display: inline-block;
      width: 5px;
      height: 5px;
      border-right: 1px solid currentColor;
      border-bottom: 1px solid currentColor;
      transform: rotate(45deg);
      margin-left: 7px;
      margin-top: -2px;
      transition: transform 0.3sease-in-out, color 0.3sease-in-out;
    }
  }

  &.hovered .arrow {
    transition: all 0.4s ease;
  }
  .down-arrow {
    margin-top: -7px;
    position: absolute;
    right: 0;
    top: 50%;
    bottom: 0;
  }

  &:hover {
    span {
      opacity: 60%;

      &:after {
        transform: rotate(-43deg); /* Smoothly rotates the arrow upwards */
      }
    }

    &:after {
      content: "";
      position: absolute;
      width: 200px;
      bottom: -25px;
      padding-top: 50px;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      width: 112px;
      bottom: 0;
      padding-top: 70px;
    }
    .megaMenu {
      display: block;
      transition: all 5s ease-in-out;
    }
    .down-arrow {
      transition: all 0.3s ease;
      transform: rotate(-90deg);
      opacity: 60%;
    }
  }
  @media (max-width: 1200px) {
    position: unset;
    font-size: 12px;
    font-weight: 500;
    &:after {
      display: none;
    }
    span {
      letter-spacing: 0.5px;
    }
  }
  @media (max-width: 1024px) {
    position: unset;
    font-size: 10px;
    font-weight: 500;
    &:after {
      display: none;
    }
    span {
      letter-spacing: 0.5px;
    }
  }
`;
export const HeaderLogoLeft = styled.div``;
export const HeaderRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  &.paddingInside {
    div {
      min-height: unset;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: var(--text-color-dark);
    }
  }
  @media (min-width: 1440px) {
    gap: 1.5rem;
  }
  @media (max-width: 1024px) {
    gap: 0rem;
  }
  @media (max-width: 768px) {
    &.paddingInside {
      div {
        min-height: unset;
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
      }
    }
  }
`;
export const LandingHeaderMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-color);
  //min-height: 100.5px;

  .active {
    position: fixed;
    top: 1rem !important;
    left: 0;
    width: 100%;
    margin: 0 auto;
    right: 0;
    border-radius: 14px;
    z-index: 9999;
    padding: 0 12px !important;
    transition: all 0.2s ease-in-out;
  }
  @media (max-width: 1024px) {
    min-height: 100px;

    .active {
      width: calc(100% - 23px);
      padding: 1rem;
    }
  }
  @media only screen and (max-width: 840px) and (max-height: 500px) {
    display: none;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

export const MobileviewHeader = styled.div`
  display: none;

  @media only screen and (max-width: 950px) and (max-height: 500px) {
    display: block;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    transition: top 0.4s ease;

    &.active {
      position: fixed;
      top: 1rem !important;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: #1d1e22;
      width: calc(100% - 23px);
      padding: 12px 1rem;
      z-index: 25;
      border-radius: 9px;
      transition: top 0.4s ease;
    }
  }
  @media (max-width: 600px) {
    display: block;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    transition: top 0.4s ease;
    height: 63px;
    min-height: 63px;
    max-height: 63px;

    &.active {
      position: fixed;
      top: 1rem !important;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: #1d1e22 !important;
      width: calc(100% - 23px);
      padding: 12px 1rem;
      z-index: 25;
      border-radius: 9px;
      transition: top 0.4s ease;
      height: 63px;
      min-height: 63px;
      max-height: 63px;
    }
  }
  &.open {
    color: #fff;
    background: #1d1e22;
  }
`;

export const MobileHeaderInner = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  .dark {
    color: #fff;
  }

  svg {
    height: 25px;
    width: 25px;
  }
`;
export const LogoLink = styled.div`
  .whiteLogo {
    height: 35px;
    width: 100%;
  }
  .darkLogo {
    height: 35px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    .whiteLogo {
      height: 35px;
      width: 100%;
    }
    .darkLogo {
      height: 35px;
      width: 100%;
    }
    img {
      width: 100%;
      height: 35px;
    }
  }
  @media (max-width: 820px) {
    img {
      width: 100%;
      height: 30px;
    }
  }
  @media (max-width: 786px) {
    .whiteLogo {
      height: 30px;
      width: 100%;
    }
    .darkLogo {
      height: 30px;
      width: 100%;
    }

    img {
      width: 100%;
      height: 30px;
    }
  }
  @media (max-width: 475px) {
    svg {
      height: 30px !important;
      width: auto !important;
    }
  }
`;
export const Talkbutton = styled.a`
  border-radius: 25px;
  font-weight: 600;
  padding: 7px 20px;
  border: 0px;
  background: var(--backgoundbtn);
  color: #fff;

  &.header-btn {
    letter-spacing: 2px;
    font-size: 12px;
    line-height: 15px;
    ${"" /* transition: background-color 0.2s; */}
    text-transform: uppercase;
    padding-left: 15.5px;
    padding-right: 15.5px;
    border-radius: 8px;
    margin-left: 18px;
    padding-top: 15px;
    padding-bottom: 14px;
  }
  &.bg-change {
    background: #f00038;
    color: #fff;
    letter-spacing: 2px;
    font-size: 12px;
    line-height: 15px;
    ${"" /* transition: background-color 0.2s; */}
    border: none;
    text-transform: uppercase;
    padding-left: 15.5px;
    padding-right: 15.5px;
    border-radius: 8px;
    margin-left: 0px;
    padding-top: 15px;
    padding-bottom: 14px;

    &:hover {
      background: #f00038 !important;
      color: #fff !important;
    }
  }

  &:hover {
    background: #f00038 !important;
    color: #fff !important;
  }
  @media (min-width: 1440px) {
    &.header-btn {
      padding-left: 45.5px;
      padding-right: 45.5px;
      padding-top: 17px;
      padding-bottom: 16px;
    }
    &.bg-change {
      padding-left: 45.5px;
      padding-right: 45.5px;
      padding-top: 17px;
      padding-bottom: 16px;
    }
  }
  @media (max-width: 1024px) {
    &.bg-change {
      letter-spacing: 2px;
      font-size: 12px;
      padding-left: 30.5px;
      padding-right: 30.5px;
      border-radius: 8px;
      margin-left: 18px;
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
  @media (max-width: 820px) {
    font-size: 11px;
    padding: 7px 12px;
    &.header-btn {
      text-transform: capitalize;
      padding-left: 12.5px;
      padding-right: 12.5px;
      margin-left: 18px;
      padding-top: 10px;
      padding-bottom: 8px;
      letter-spacing: 1px;
    }
    &.bg-change {
      font-size: 11px;
      letter-spacing: 0;
      line-height: unset;
      padding-left: 12.5px;
      padding-right: 12.5px;
      border-radius: 8px;
      margin-left: 18px;
      padding-top: 10px;
      padding-bottom: 8px;
    }
  }
  @media (max-width: 600px) {
    &.bg-change {
      padding-left: 14.5px;
      padding-right: 14.5px;
      border-radius: 4px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
    &.header-btn {
      font-size: 11px;
      padding: 7px 12px;
      border-radius: 25px;
      font-weight: 600;
      text-transform: capitalize;
      letter-spacing: normal;
    }
  }
`;
export const HearderSideBar = styled.div`
  display: none;
  position: fixed;
  top: -1000px;
  right: 0;
  left: 0;
  height: calc(100vh - 93px);
  overflow: auto;
  z-index: 25;
  color: #fff;
  background: #1d1e22;
  padding: 1.5rem 1rem;
  margin: 0 auto;
  border-radius: 9px;
  width: calc(100% - 23px);
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;

  &.active {
    top: 75px;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  }
  ${
    "" /* @media (max-width: 600px) {
    display: block;
  } */
  }
  @media only screen and (max-width: 950px) and (max-height: 500px) {
    display: block !important;

    &.active {
      top: 84px;
    }
  }
  @media (max-width: 600px) {
    display: block;
  }
`;

export const SidebarItems = styled.div`
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 10px 0;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #323030;
  text-transform: uppercase;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;

  &.borderColor {
    border-bottom: 1px solid #1d1e22;
  }
`;

export const SideBarItemsMain = styled.div`
  padding: 1rem 0;
`;

export const LetsTalkButton = styled.button`
  margin-top: 2rem;
  background: #f00038;
  color: #fff;
  width: 100%;
  padding: 12px 15px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 300;
`;
export const ServicesDropMain = styled.div`
  height: 50px;
  overflow: hidden;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;

  &.active {
    height: 535px;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  }
  &.Technologies {
    height: 715px;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  }
  &.Industry {
    height: 575px;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  }
  &.Platform {
    height: 479px;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  }
  &.HireDevelopers {
    height: 632px;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  }
  &.Company {
    height: 628px;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  }
`;
export const ServicesDropInner = styled.div`
  padding: 0px 15px 10px 0;
  border-bottom: 1px solid #323030;
`;
export const ServicesInnerItems = styled.div`
  padding: 10px 0;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.5px;
`;
export const PlatformMobileSubMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #323030;
`;
export const PlatformMenuDropInner = styled.div`
  padding-bottom: 10px;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  width: ${(props) => (props.w ? props.w : ``)};
  position: relative;

  .platform-inner {
    font-size: 12px;
  }

  &:after {
    content: "";
    position: absolute;
    width: 0.5px;
    height: 100%;
    top: 0;
    background: #ffffff6b;
    right: 0;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`;
export const HireDevelopersMobileSubMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #323030;
`;
export const HireDevelopersMenuDropInner = styled.div`
  padding-bottom: 10px;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  width: ${(props) => (props.w ? props.w : ``)};
  position: relative;

  .platform-inner {
    font-size: 12px;
  }

  &:after {
    content: "";
    position: absolute;
    width: 0.5px;
    height: 100%;
    top: 0;
    background: #ffffff6b;
    right: 0;
  }

  &.sec_section {
    &:after {
      display: none;
    }
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`;
export const TechnologiesDropInner = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #323030;
  flex-wrap: wrap;
  gap: 1rem;
`;
export const ServicesSubHeader = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #959595;
  padding: 1rem 0;
`;
export const MegaHeaderBookIcon = styled.div`
  background-color: #26272b;
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  width: 21%;
  margin-bottom: 0;
  padding: 1rem;
  display: flex;
  position: relative;
  min-height: 294px !important;

  &.Industry-tab-2 {
    width: 25%;

    li {
      line-height: 30px;
    }
  }
  &.Industry-tab-1 {
    width: 40%;
  }

  @media (max-width: 1024px) and (min-width: 820px) {
    &.i_pad_minHeight {
      min-height: 381px !important;
    }
  }

  @media (max-width: 600px) {
    &.mobile-view {
      width: 100%;

      ul {
        li {
          svg {
            display: unset;
          }
        }
      }
    }
  }
`;
export const CompanyOtherItems = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem;

  @media (max-width: 600px) {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    gap: 2rem;
  }
`;
export const CompanyInnerItems = styled.div`
  width: 48%;
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  transition: all 0.5s ease;
  a {
    display: flex;
  }
  svg {
    margin-right: 16px;
    min-width: 39px;
    opacity: 0.6;
  }
  &:hover {
    transition: all 0.5s ease;

    svg {
      opacity: 1;
      transition: all 0.5s ease;
    }
    .sub-text {
      opacity: 0.9;
      transition: all 0.5s ease;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    padding: 0;

    svg {
      margin-right: 20px;
      min-width: 30px;
      opacity: 0.6;
    }
  }
`;
export const CompanyContant = styled.div``;
export const CompanyItemsTitle = styled.div`
  color: #fff;
  letter-spacing: 0;
  text-transform: none;
  font-size: 16px !important;
  font-weight: 300;
  text-decoration: none;
  display: flex;
  align-items: center;

  svg {
    opacity: 0;
  }

  @media (max-width: 600px) {
    font-size: 14px !important;
    gap: 0.5rem;

    svg {
      opacity: 0.7;
      font-size: 12px;
      min-width: 20px;
    }
  }
`;
export const CompanyItemsSubTitle = styled.div`
  opacity: 0.6;
  letter-spacing: 0;
  text-transform: none;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 14px !important;
  font-weight: 300;
  color: #fff;
  line-height: 24px !important;

  @media (max-width: 600px) {
    font-size: 12px !important;
    margin-top: 5px;
    line-height: 18px !important;
  }
`;
export const MegaSubInsideTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  color: #fff;
  letter-spacing: 1.2px;
  padding-bottom: 15px;
  //padding-left: 35px;
`;
export const MegaCompanySection = styled.div`
  position: relative;

  li {
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    padding: 7px 0;
    color: #fff;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.3s;
    cursor: pointer;

    a {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
    }

    svg {
      display: none;
      transform: translate3d(0px, 4px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.3s;
      min-width: 14px;
    }

    &:hover {
      color: #f80263;
      transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.3s;

      svg {
        color: #fff;
        transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.3s;
        transform: translate3d(7px, 4px, 0px) scale3d(1, 1, 1) rotateX(0deg)
          rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      }
    }
  }
  &.platform-line {
    &:after {
      content: "";
      position: absolute;
      width: 0.5px;
      height: 100%;
      top: 0;
      background: #ffffff6b;
      right: -50px;
      z-index: 1;
    }
  }
  @media (min-width: 1440px) {
    li {
      line-height: 30px;
    }
    &.compnay-li {
      li {
        line-height: 19px;
      }
    }
  }
  @media (max-width: 1366px) {
    &.platform-line {
      &:after {
        right: -15px;
      }
    }
    li {
      gap: 12px;
      display: flex;
    }
  }
  @media (max-width: 820px) {
    li {
      font-size: 13px;
      line-height: 20px;

      svg {
        display: none;
      }
    }
  }
  @media (max-width: 768px) {
    li {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
export const MegaMenuMain = styled.div`
  display: none;
  position: absolute;
  top: 90px;
  z-index: 120;
  background: #fff;
  color: #000;
  padding: 1.5rem;
  width: 100%;
  left: 0;
  border-radius: 12px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  &.bgDark {
    background: #1d1e22;
    top: 90px;
  }
  @media (max-width: 1366px) {
    padding: 1rem;
  }

  @media (max-width: 1024px) {
    top: 85px;
    left: 0;
    width: 100%;
    padding: 1rem;
  }
  @media (max-width: 820px) {
    width: calc(100% - 16px);
    left: 0;
    right: 0;
    margin: auto;

    &.bgDark {
      top: 75px;
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    &.bgDark {
      top: 70px;
    }
  }
`;

export const CompanyResponciveMain = styled.div`
  @media (max-width: 1366px) {
    width: 100%;
    overflow: auto;
    padding-bottom: 1rem;

    &::-webkit-scrollbar {
      width: 1px;
      height: 1px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;
export const HireMegaMenuDesign = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;

  &.technology-m {
    ul {
      border-left: 1px solid #404040;
      padding-left: 15px;
    }
    li {
      font-size: 14px;
      line-height: 24px;
      font-weight: 300;
      padding: 0px;
      margin-bottom: 6px;
      transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.3s;
      cursor: pointer;

      a {
        svg {
          display: none;
        }
      }
    }
  }
  &.services_tab {
    width: 100%;
    display: block;
  }
  @media (min-width: 1440px) {
    &.technology-m {
      li {
        line-height: 30px;
      }
    }
  }
  @media (max-width: 1366px) {
    &.company-tab {
      width: 1200px;
    }
    &.hire-developer-tab {
      width: 1480px;
    }
    &.services_tab {
      width: 1220px;
    }
  }
  @media (max-width: 820px) {
    &.hire-developer-tab {
      width: 970px;
    }
  }
  @media (max-width: 768px) {
    &.hire-developer-tab {
      width: 933px;
    }
  }
`;
export const MegaMenuInner = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  @media (max-width: 1024px) {
    gap: 2px;

    &.header-mega-first {
      gap: 1rem;
    }
  }
`;
export const MegaInnerItems = styled.div`
  background-color: #26272b;
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
  padding: 1rem;
  display: flex;
  position: relative;
  min-height: 374.57px !important;
  width: 23%;

  &.companyMenu {
    min-width: 150px;
  }
  &.plat-form {
    width: 70%;
  }

  ul {
    padding-left: 0;
  }

  li {
    font-size: 18px;
    line-height: 44px;
    font-weight: 500;
    padding: 0px 30px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.15s ease;

    &:hover {
      background: rgba(136, 136, 136, 0.05);
      color: #f80263;
    }
  }
  @media (max-width: 1366px) {
    width: 27%;

    &.industry-tab {
      width: 26%;
      min-height: 492px !important;
    }
    &.plat-form {
      min-height: 492px !important;
    }
  }
  @media (max-width: 1024px) {
    &.last-Item {
      margin-top: 2rem;
    }
    li {
      font-size: 13px;
    }
    &.companyMenu {
      display: none;
    }
    &.industry-tab {
      width: 26%;
      min-height: 392px !important;
    }
    &.plat-form {
      min-height: 392px !important;
      width: 580px;
    }
  }

  @media (max-width: 820px) {
    &.tabView {
      display: none;
    }
    &.sec-last-Item {
      margin-top: 2rem;
    }
    .otherTitle {
      display: none;
    }
    &.industry-tab {
      width: 22%;
      min-height: 464px !important;
    }
    &.plat-form {
      min-height: 464px !important;
    }
  }
  @media (max-width: 768px) {
    li {
      font-size: 12px;
    }
    &.industry-tab {
      width: 24%;
      min-height: 390px !important;
    }
    &.plat-form {
      min-height: 390px !important;
    }
  }
`;
export const MegaHireHeading = styled.div`
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  width: 100%;
  letter-spacing: 1.2px;
  padding-bottom: 15px;
  opacity: 0.6;

  @media (max-width: 820px) {
    font-weight: 600;
  }
`;
export const MegaPlatformItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
`;

export const MegaPlatformInner = styled.div`
  .special-page {
    display: flex;
    flex-direction: column;
    position: relative;
    //top: -20px;
    gap: 5px;
    padding: 20px 0px;
    li {
      font-size: 18px;
      line-height: 40px;
      font-weight: 500;
      //padding: 8px 25px;
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.15s ease;
      &:hover {
        background: rgba(136, 136, 136, 0.05);
        color: #f80263;
      }
      @media (max-width: 1024px) {
        font-size: 14px;
      }
    }
  }
`;
export const ReadyDesignButton = styled.button`
  background: #ff8e5e;
  background: ${(props) => (props.bg ? props.bg : ``)};
  padding: 10px 15px;
  border-radius: 7px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 12px;
  text-transform: uppercase;
  /* margin-top: 3rem; */
  &.getButton {
    background: #ff8e5e;
  }

  @media (max-width: 1024px) {
    font-size: 13px;
  }

  @media (max-width: 769px) {
    font-size: 14px;
  }
  @media (max-width: 600px) {
    font-size: 11px !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    gap: 10px;
  }
`;
export const MegaMenuInnerHire = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;

  @media (max-width: 1366px) {
    width: 75%;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  div:nth-child(4) {
    .platform-line {
      &:after {
        display: none;
      }
    }
  }
`;
export const MegaHireNew = styled.div`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
  padding: 1rem;
  display: flex;
  position: relative;
  width: 25%;

  &.first-section {
    width: 20%;
  }
  &.responcive-ipadview {
    display: none;
  }

  @media (max-width: 1366px) {
    width: 33%;

    &.first-section {
      width: 25%;
    }
    &.last-Item {
      display: none;
    }
    &.responcive-ipadview {
      display: block;
      width: 100%;
      margin-top: 1rem;
      min-height: unset !important;

      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
  }

  @media (max-width: 1024px) {
    margin-right: 0;
    width: 31.7%;
    padding-left: 1rem;
  }
  @media (max-width: 820px) {
    width: 33%;
    min-height: 356.57px !important;

    &.hire-section {
      min-height: 336px !important;
    }
  }
  @media (max-width: 768px) {
    min-height: 340.57px !important;
    &.hire-section {
      min-height: 321px !important;
    }
  }
`;
export const CompanyMenuInnerHire = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 60%;
  &.Industry-tab-4 {
    width: 25%;
    .tb-4 {
      height: 100%;
    }
    img {
      filter: drop-shadow(0px 10px 23px rgba(0, 0, 0, 0.38));
      margin-left: 20px;
    }
  }
`;
export const CompanyBgSection = styled.div`
  background-color: #26272b;
  border-radius: 12px;
  padding: 1.3rem;
  min-height: 294px !important;
`;
export const MegaImageSection = styled.div`
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;

  img {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(-1.65deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    width: 100%;
    margin-bottom: 10px;
    margin-left: -20px;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  }
  &:hover {
    img {
      transform: translate3d(0px, 0px, 0px) scale3d(1.03, 1.03, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(-3deg) skew(0deg, 0deg);
      transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
    }
    .lets-talk {
      opacity: 0.8;
    }
  }
  @media (max-width: 1024px) {
    img {
      margin-left: -10px;
    }
  }
`;
export const MegaBottomButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #ffff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.5px;
  padding-top: 10px;
`;